$enable-validation-icons: false;

@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/bootstrap-icons/font/bootstrap-icons.scss";

@import url("https://fonts.googleapis.com/css?family=Nunito:400,600,700,800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Aldrich&family=Alegreya+SC&family=Averia+Sans+Libre&family=Merienda+One&family=Nothing+You+Could+Do&family=Righteous&family=Sancreek&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;800&family=Poppins:wght@500;700&display=swap');

:root {
  scroll-behavior: smooth;
  --dark-blue: #001e3c;
  --dark-white: #fff;
  --dark-blue-rgb: 0, 30, 60;
  --blue: #004f7f;
  --light-blue: #56b6e7;
  --light-blue-rgb: 86, 182, 231;
  --orange-rgb: 253, 126, 20;
  --pink-rgb: 214, 51, 132;
  --black: #000000;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: transparent;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ccc;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--bs-warning);
}

body {
  font-family: 'Nunito', Arial, sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background-color: #fafafa;
  overflow-x: hidden;
  min-height: 100vh;
  overflow-y: scroll;
}

body.loading-data {
  pointer-events: none;
}

body.loading-data::before {
  content: '';
  position: fixed;
  border-radius: 20px;
  left: 0;
  background: -webkit-gradient(linear, right top, left top, from(var(--black)), to(#ffc107)) !important;
  background: linear-gradient(to left, var(--black), #ffc107) !important;
  width: 0;
  height: 3px;
  z-index: 9999;
  -webkit-animation: tableloadingBar 4s linear infinite;
  animation: tableloadingBar 4s linear infinite;
}

@-webkit-keyframes tableloadingBar {
  0% {
    width: 0%;
  }

  50% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

@keyframes tableloadingBar {
  0% {
    width: 0%;
  }

  50% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

#root~iframe[style*="2147483647"] {
  z-index: -1000 !important;
  display: none;
}

.invalid-feedback,
.invalid-custom-feedback {
  color: #ff0000;
  font-size: 12px;
  font-style: normal;
}

.l {
  -webkit-box-shadow: inset 0 0 0 1px red;
  box-shadow: inset 0 0 0 1px red;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 0px transparent inset !important;
  box-shadow: 0 0 0 0px transparent inset !important;
  -webkit-transition: background-color 600000s 0s, color 600000s 0s;
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

input[readonly] {
  background-color: #ced4da;
  pointer-events: none;
}

textarea[readonly] {
  background-color: #ced4da;
  pointer-events: none;
}

.icon {
  stroke: unset;
  stroke-width: 0px;
  fill: currentColor;
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
}

.icon-h16 {
  height: 16px !important;
}

.icon-h17 {
  height: 17px !important;
}

.icon-h18 {
  height: 18px !important;
}

.icon-h19 {
  height: 19px !important;
}

[class*="bi-"] {
  display: inline-block;
  vertical-align: -0.05em;
}

.bi-0p0,
.tscale-0p0 {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.bi-0p1,
.tscale-0p1 {
  -webkit-transform: scale(0.1);
  transform: scale(0.1);
}

.bi-0p2,
.tscale-0p2 {
  -webkit-transform: scale(0.2);
  transform: scale(0.2);
}

.bi-0p3,
.tscale-0p3 {
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
}

.bi-0p4,
.tscale-0p4 {
  -webkit-transform: scale(0.4);
  transform: scale(0.4);
}

.bi-0p5,
.tscale-0p5 {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}

.bi-0p6,
.tscale-0p6 {
  -webkit-transform: scale(0.6);
  transform: scale(0.6);
}

.bi-0p7,
.tscale-0p7 {
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
}

.bi-0p8,
.tscale-0p8 {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.bi-0p9,
.tscale-0p9 {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.bi-1p0,
.tscale-1p0 {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.bi-1p1,
.tscale-1p1 {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.bi-1p2,
.tscale-1p2 {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.bi-1p3,
.tscale-1p3 {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.bi-1p4,
.tscale-1p4 {
  -webkit-transform: scale(1.4);
  transform: scale(1.4);
}

.bi-1p5,
.tscale-1p5 {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.bi-1p6,
.tscale-1p6 {
  -webkit-transform: scale(1.6);
  transform: scale(1.6);
}

.bi-1p7,
.tscale-1p7 {
  -webkit-transform: scale(1.7);
  transform: scale(1.7);
}

.bi-1p8,
.tscale-1p8 {
  -webkit-transform: scale(1.8);
  transform: scale(1.8);
}

.bi-1p9,
.tscale-1p9 {
  -webkit-transform: scale(1.9);
  transform: scale(1.9);
}

.bi-2p0,
.tscale-2p0 {
  -webkit-transform: scale(2);
  transform: scale(2);
}

.bi-2p1,
.tscale-2p1 {
  -webkit-transform: scale(2.1);
  transform: scale(2.1);
}

.bi-2p2,
.tscale-2p2 {
  -webkit-transform: scale(2.2);
  transform: scale(2.2);
}

.bi-2p3,
.tscale-2p3 {
  -webkit-transform: scale(2.3);
  transform: scale(2.3);
}

.bi-2p4,
.tscale-2p4 {
  -webkit-transform: scale(2.4);
  transform: scale(2.4);
}

.bi-2p5,
.tscale-2p5 {
  -webkit-transform: scale(2.5);
  transform: scale(2.5);
}

.bi-2p6,
.tscale-2p6 {
  -webkit-transform: scale(2.6);
  transform: scale(2.6);
}

.ts-1p05-350ms {
  -webkit-transition: 350ms;
  transition: 350ms;
}

.ts-1p05-350ms:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.ts-1p1-350ms {
  -webkit-transition: 350ms;
  transition: 350ms;
}

.ts-1p1-350ms:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.ts-1p15-300ms {
  -webkit-transition: 350ms;
  transition: 350ms;
}

.ts-1p15-300ms:hover {
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}

.lh-md {
  line-height: 1.7 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: 800 !important;
}

.opacity-5 {
  opacity: 0.05;
}

.opacity-10 {
  opacity: 0.10;
}

.opacity-15 {
  opacity: 0.15;
}

.opacity-20 {
  opacity: 0.20;
}

.rounded-6 {
  border-radius: 6px;
}

.rounded-8 {
  border-radius: 8px;
}

.rounded-10 {
  border-radius: 10px;
}

.rounded-15 {
  border-radius: 15px;
}

.rounded-20 {
  border-radius: 20px;
}

.rounded-25 {
  border-radius: 25px;
}

.mmw-20 {
  min-width: 20px;
  max-width: 20px;
}

.mmw-30 {
  min-width: 30px;
  max-width: 30px;
}

.mmw-40 {
  min-width: 40px;
  max-width: 40px;
}

.mmw-50 {
  min-width: 50px;
  max-width: 50px;
}

.mmw-60 {
  min-width: 60px;
  max-width: 60px;
}

.mmw-70 {
  min-width: 70px;
  max-width: 70px;
}

.mmw-80 {
  min-width: 80px;
  max-width: 80px;
}

.mmw-100 {
  min-width: 100px;
  max-width: 100px;
}

.mmw-120 {
  min-width: 120px;
  max-width: 120px;
}

.mmw-140 {
  min-width: 140px;
  max-width: 140px;
}

.mmw-150 {
  min-width: 150px;
  max-width: 150px;
}

.mmw-160 {
  min-width: 160px;
  max-width: 160px;
}

.mmw-170 {
  min-width: 170px;
  max-width: 170px;
}

.mmw-180 {
  min-width: 180px;
  max-width: 180px;
}

.mmw-200 {
  min-width: 200px;
  max-width: 200px;
}

.mmw-220 {
  min-width: 220px;
  max-width: 220px;
}

.mmw-240 {
  min-width: 240px;
  max-width: 240px;
}

.mmw-250 {
  min-width: 250px;
  max-width: 250px;
}

.minw-auto {
  min-width: auto;
}

.minw-20 {
  min-width: 20px;
}

.minw-25 {
  min-width: 25px;
}

.minw-30 {
  min-width: 30px;
}

.minw-35 {
  min-width: 35px;
}

.minw-36 {
  min-width: 36px;
}

.minw-40 {
  min-width: 40px;
}

.minw-50 {
  min-width: 50px;
}

.minw-100 {
  min-width: 100px;
}

.minw-110 {
  min-width: 110px;
}

.minw-115 {
  min-width: 115px;
}

.minw-120 {
  min-width: 120px;
}

.minw-125 {
  min-width: 125px;
}

.minw-130 {
  min-width: 130px;
}

.minw-150 {
  min-width: 150px;
}

.minw-160 {
  min-width: 160px;
}

.minw-170 {
  min-width: 170px;
}

.minw-180 {
  min-width: 180px;
}

.minw-190 {
  min-width: 190px;
}

.minw-200 {
  min-width: 200px;
}

.minw-280 {
  min-width: 280px;
}

.minw-300 {
  min-width: 300px;
}

.w-300 {
  width: 300px !important;
}

.minh-auto {
  min-height: auto;
}

.minh-18 {
  min-height: 18px;
}

.minh-19 {
  min-height: 19px;
}

.minh-20 {
  min-height: 20px;
}

.minh-22 {
  min-height: 22px;
}

.minh-40 {
  min-height: 40px;
}

.minh-45 {
  min-height: 45px;
}

.minh-50 {
  min-height: 50px;
}

.minh-55 {
  min-height: 55px;
}

.minh-100 {
  min-height: 100px !important;
}

.minh-120 {
  min-height: 120px !important;
}

.minh-140 {
  min-height: 140px;
}

.minh-170 {
  min-height: 170px !important;
}

.minh-180 {
  min-height: 180px;
}

.minh-200 {
  min-height: 200px !important;
}

.wh-20 {
  width: 20px;
  height: 20px;
}

.wh-25 {
  width: 25px;
  height: 25px;
}

.wh-30 {
  width: 30px;
  height: 30px;
}

.wh-35 {
  width: 35px;
  height: 35px;
}

.wh-40 {
  width: 40px;
  height: 40px;
}

.wh-45 {
  width: 45px;
  height: 45px;
}

.wh-50 {
  width: 50px;
  height: 50px;
}

.wh-60 {
  width: 60px;
  height: 60px;
}

.wh-70 {
  width: 70px;
  height: 70px;
}

.wh-80 {
  width: 80px;
  height: 80px;
}

.maxw-20 {
  max-width: 20px;
}

.maxw-24 {
  max-width: 24px;
}

.maxw-25 {
  max-width: 25px;
}

.maxw-30 {
  max-width: 30px;
}

.maxw-35 {
  max-width: 35px;
}

.maxw-40 {
  max-width: 40px;
}

.maxw-45 {
  max-width: 45px;
}

.maxw-50 {
  max-width: 50px;
}

.maxw-55 {
  max-width: 55px;
}

.maxw-60 {
  max-width: 60px;
}

.maxw-70 {
  max-width: 70px;
}

.maxw-80 {
  max-width: 80px;
}

.maxw-100 {
  max-width: 100px;
}

.maxw-120 {
  max-width: 120px;
}

.maxw-140 {
  max-width: 140px;
}

.maxw-160 {
  max-width: 160px;
}

.maxw-180 {
  max-width: 180px;
}

.maxw-190 {
  max-width: 190px;
}

.maxw-200 {
  max-width: 200px;
}

.maxw-220 {
  max-width: 220px;
}

.maxw-230 {
  max-width: 230px;
}

.maxw-240 {
  max-width: 240px;
}

.maxw-250 {
  max-width: 250px;
}

.maxw-260 {
  max-width: 260px;
}

.maxw-300 {
  max-width: 300px;
}

.maxw-320 {
  max-width: 320px;
}

.maxh-20 {
  max-height: 20px;
}

.maxh-22 {
  max-height: 22px;
}

.maxh-24 {
  max-height: 24px;
}

.maxh-26 {
  max-height: 26px;
}

.maxh-28 {
  max-height: 28px;
}

.maxh-30 {
  max-height: 30px;
}

.maxh-40 {
  max-height: 40px;
}

.maxh-50 {
  max-height: 50px;
}

.maxh-80 {
  max-height: 80px;
}

.maxh-100 {
  max-height: 100px;
}

.maxh-150 {
  max-height: 150px;
}

.border-orange {
  border-color: var(--bs-orange) !important;
}

.border-pink {
  border-color: var(--bs-pink) !important;
}

.text-blue {
  color: var(--blue);
}

.text-light-blue {
  color: var(--light-blue);
}

.text-orange {
  color: var(--bs-orange);
}

.text-pink {
  color: var(--bs-pink);
}

.bg-dark-blue {
  background-color: var(--dark-blue) !important;
}

.bg-light-blue {
  background-color: var(--light-blue);
}

.bg-blue-light-25 {
  background-color: rgba(var(--light-blue-rgb), 0.25);
}

.bg-blue-light-15 {
  background-color: rgba(var(--light-blue-rgb), 0.15);
}

.bg-info-light-15 {
  background-color: rgba(var(--bs-info-rgb), 0.15);
}

.bg-warning-light-15 {
  background-color: rgba(var(--bs-warning-rgb), 0.15);
}

.bg-danger-light-15 {
  background-color: rgba(var(--bs-danger-rgb), 0.15);
}

.bg-primary-light-15 {
  background-color: rgba(var(--bs-primary-rgb), 0.15);
}

.bg-secondary-light-15 {
  background-color: rgba(var(--bs-secondary-rgb), 0.15);
}

.bg-secondary-light-10 {
  background-color: rgba(var(--bs-secondary-rgb), 0.1);
}

.bg-success-light-15 {
  background-color: rgba(var(--bs-success-rgb), 0.15);
}

.bg-orange-light-15 {
  background-color: rgba(var(--orange-rgb), 0.15);
}

.bg-pink-light-15 {
  background-color: rgba(var(--pink-rgb), 0.15);
}

.objfit-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.objpos-center {
  -o-object-position: center;
  object-position: center;
}

.objpos-top {
  -o-object-position: top;
  object-position: top;
}

.objpos-bottom {
  -o-object-position: bottom;
  object-position: bottom;
}

.ratio-80p {
  --bs-aspect-ratio: 80%;
}

.ratio-82p {
  --bs-aspect-ratio: 82%;
}

.ratio-85p {
  --bs-aspect-ratio: 85%;
}

.ratio-102p {
  --bs-aspect-ratio: 102%;
}

.ratio-105p {
  --bs-aspect-ratio: 105%;
}

.ratio-110p {
  --bs-aspect-ratio: 110%;
}

.ps-50 {
  padding-left: 50px;
}

.ps-70 {
  padding-left: 70px;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-11 {
  margin-top: 11px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-13 {
  margin-top: 13px !important;
}

.mt-65pc {
  margin-top: 65% !important;
}

.mt-90pc {
  margin-top: 91% !important;
}

.mt-33pc {
  margin-top: 33% !important;
}
.mt-25pc {
  margin-top: 25% !important;
}
.mt-145pc {
  margin-top: 145px !important;
}


.w2b {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

input[type="range"] {
  outline: none;
}

.form-check-input:checked {
  background-color: var(--dark-blue);
  border-color: var(--dark-blue);
}

.form-check-input:focus {
  border-color: rgba(var(--dark-blue-rgb), 0.75);
  -webkit-box-shadow: 0 0 0 0.25rem rgba(var(--dark-blue-rgb), 0.25);
  box-shadow: 0 0 0 0.25rem rgba(var(--dark-blue-rgb), 0.25);
}

.input-group-rounded>.btn,
.input-group-rounded>.form-control,
.input-group-rounded>.form-select,
.input-group-rounded>.input-group-text {
  padding: 0.5rem 1rem 0.55rem 1rem;
  font-size: 1.18rem;
  border-radius: 5rem;
}

.btn-group-lg>.btn,
.btn-lg {
  padding: 0.5rem 1rem 0.55rem 1rem;
  font-size: 1.18rem;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--blue);
  border-color: var(--blue);
}

.btn-primary {
  font-weight: 600;
  color: #fff;
  background-color: var(--dark-blue);
  border-color: var(--dark-blue);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.btn-primary:hover,
.btn-primary:focus {
  color: var(--dark-white);
  background-color: var(--bs-warning);
  border-color: var(--bs-warning);
  -webkit-box-shadow: 0 12px 20px -12px rgba(25, 104, 252, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(25, 104, 252, 0.2) !important;
  box-shadow: 0 12px 20px -12px rgba(25, 104, 252, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(25, 104, 252, 0.2) !important;
}

.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--dark-blue);
  border-color: var(--dark-blue);
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
  background-color: var(--dark-blue);
  border-color: rgba(var(--dark-blue-rgb), 0.5);
  -webkit-box-shadow: 0 0 0 0.25rem rgba(var(--dark-blue-rgb), 0.15);
  box-shadow: 0 0 0 0.25rem rgba(var(--dark-blue-rgb), 0.15);
}

.btn-check:focus+.btn,
.btn:focus {
  border-color: rgba(var(--dark-blue-rgb), 0.5);
  -webkit-box-shadow: 0 0 0 0.25rem rgba(var(--dark-blue-rgb), 0.15);
  box-shadow: 0 0 0 0.25rem rgba(var(--dark-blue-rgb), 0.15);
}

.border-start-light {
  border-left: 1px solid var(--bs-light) !important;
}

.border-bottom-light {
  border-bottom: 1px solid var(--bs-light) !important;
}

.form-control:hover,
.form-select:hover {
  border-color: rgba(var(--dark-blue-rgb), 0.5);
}

.form-control:focus,
.form-select:focus {
  color: var(--dark-blue);
  background-color: #fff;
  border-color: rgba(var(--dark-blue-rgb), 0.5);
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(var(--dark-blue-rgb), 0.15);
  box-shadow: 0 0 0 0.25rem rgba(var(--dark-blue-rgb), 0.15);
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--bs-dark);
}

.modal-backdrop.show,
.offcanvas-backdrop.show {
  opacity: .70;
}

.rounded-pill-end-topbottom {
  border-top-right-radius: 40px !important;
  border-bottom-right-radius: 40px !important;
}

.image-uploading {
  position: relative;
  overflow: hidden;
}

.image-uploading img {
  width: 120px;
  height: 120px;
  min-width: 120px;
  max-height: 120px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  border: 1px solid #eee;
  border-radius: 10px;
}

.image-uploading input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
}

/*==================================================================================================*/
/* ========================== */
/* Start | Style of Front Pages*/
/* ========================== */
/*==================================================================================================*/
.read-more {
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  color: var(--bs-primary);
}

.read-more::after {
  content: '\2192';
  font-weight: 900;
  padding-left: 4px;
  font-family: Arial;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.read-more:hover::after {
  padding-left: 10px;
}

.scan-form .form-control,
.scan-form .form-select {
  background-color: transparent;
  border-radius: 0;
  border: none;
  padding-left: 0;
  padding-right: 0;
  border-bottom: 1px solid #ccc;
  color: var(--dark-blue);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.scan-form .form-control::-webkit-calendar-picker-indicator,
.scan-form .form-control::-webkit-list-button,
.scan-form .form-select::-webkit-calendar-picker-indicator,
.scan-form .form-select::-webkit-list-button {
  display: none;
  opacity: 0;
}

.scan-form .form-control:focus,
.scan-form .form-select:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 1px solid var(--dark-blue);
}

@media screen and (min-width: 1200px) {
  .container {
    width: 100%;
    max-width: 1230px;
  }

  .row-cols-xl-7>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 14.7%;
  }

  .row-cols-xl-7>.col-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
}

/*Start || Navbar*/
.navbar {
  background: #000;
  position: -webkit-sticky;
  position: sticky;
  z-index: 100;
  top: 0;
  left: 0;
  padding-top: 1px;
  padding-bottom: 1px;
}

.navbar .navbar-brand img {
  display: block;
  height: 48px;
}

.navbar .nav-item .nav-link {
  font-size: 14px;
  color: #fff;
  font-weight: 600;
}

.navbar .nav-item .nav-link.show,
.navbar .nav-item .nav-link.active {
  color: var(--bs-warning);
}

.navbar .dropdown-menu .dropdown-item {
  font-weight: 600;
  font-size: 16px;
  padding-top: 7px;
  padding-bottom: 6px;
}

.navbar .dropdown-menu .dropdown-item:hover {
  color: #fff;
  -webkit-transition: 350ms;
  transition: 350ms;
  background: #000;
}

/*Stop || Navbar*/
.ourservices-box {
  display: block;
  border-radius: 10px;
  -webkit-box-shadow: 0px 10px 25px 0px rgba(141, 104, 90, 0.1);
  box-shadow: 0px 10px 25px 0px rgba(141, 104, 90, 0.1);
  -webkit-transition: background 0.3s, border 0.3s, border-radius 0.3s, -webkit-box-shadow 0.3s;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, -webkit-box-shadow 0.3s;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s, -webkit-box-shadow 0.3s;
  margin-bottom: 30px;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  min-height: 300px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 30px 40px;
  min-height: 370px;
  background: #fff;
  text-decoration: none !important;
}

.ourservices-box:hover {
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.ourservices-box img {
  width: 100%;
  max-width: 54px;
}

.ourservices-box h2 {
  color: #000;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 700;
  padding: 20px 0px;
}

.ourservices-box p {
  font-size: 16px;
  color: #333;
}

@media (max-width: 575px) {
  .ourservices-box {
    min-height: 100px;
  }
}

.wrapping-box {
  margin: 0 0 15px 0;
  display: block;
  position: relative;
}

.wrapping-box video {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
}

.wrapping-box video::-webkit-media-controls-panel {
  background-image: none !important;
}

.case-study .case-image img {
  display: block;
  width: 100px;
  min-width: 100px;
  height: 100px;
  min-height: 100px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 6px;
  -webkit-box-shadow: 0px 4px 10px rgba(50, 50, 50, 0.25);
  box-shadow: 0px 4px 10px rgba(50, 50, 50, 0.25);
}

.case-study .case-content .heading {
  font-size: 18px;
  line-height: 1;
  color: #000;
  text-decoration: none;
  display: block;
  margin-bottom: 8px;
  font-weight: 700;
}

.case-study .case-content p {
  font-size: 15px;
}

.reviewer-box {
  position: relative;
  border-radius: 8px;
  min-height: calc(100% - 50px);
  padding: 60px 20px 20px 20px;
  margin-top: 10px;
  background-color: var(--bs-light);
  -webkit-box-shadow: inset 0 0 0 1px rgba(var(--bs-secondary-rgb), 0.1);
  box-shadow: inset 0 0 0 1px rgba(var(--bs-secondary-rgb), 0.1);
}

.reviewer-box:hover {
  -webkit-box-shadow: inset 0 0 0 1px rgba(var(--bs-secondary-rgb), 0.3);
  box-shadow: inset 0 0 0 1px rgba(var(--bs-secondary-rgb), 0.3);
}

.reviewer-box img {
  display: block;
  position: absolute;
  left: calc(50% - 40px);
  top: -50px;
  width: 80px;
  max-width: 80px;
  height: 80px;
  margin: 10px;
  border-radius: 50%;
  -webkit-box-shadow: 1px 1px 8px #ddd;
  box-shadow: 1px 1px 8px #ddd;
}

/*Stop || Home Page*/
/*Start | Home page video block*/
.infovideo-block {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  position: relative;
  background-color: #ffffff;
  overflow: visible;
  border-radius: 8px;
  padding: 20px 20px 35px 20px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  z-index: 2;
  -webkit-box-shadow: 0px 10px 25px 0px rgba(141, 104, 90, 0.1);
  box-shadow: 0px 10px 25px 0px rgba(141, 104, 90, 0.1);
}

.infovideo-block figure video {
  display: block;
  border: none;
  max-width: 100%;
  margin: 0;
  outline: none;
}

/*End | Home page video block*/
/*Start || Pricing Page*/
.pricing .pricing-box {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 0 1px var(--dark-blue);
  box-shadow: inset 0 0 0 1px var(--dark-blue);
  overflow: hidden;
}

.pricing .pricing-box .nav-pills {
  border-bottom: 1px solid var(--dark-blue);
}

.pricing .pricing-box .nav-pills button {
  border: 0;
  border-radius: 0;
  color: #001e3c;
}

.pricing .pricing-box .nav-pills button.active {
  color: white;
  background-color: #001e3c;
}

.pricing-table thead {
  background: var(--dark-blue);
  color: #fff;
}

.pricing-table thead td img {
  display: inline-block;
  width: 27px;
  margin-right: 5px;
}

.pricing-table tr {
  vertical-align: middle;
}

.pricing-table tr td,
.pricing-table tr th {
  padding: 10px;
}

.pricing-table tr td:first-child,
.pricing-table tr th:first-child {
  text-align: left;
}

.tab .accordion-button:not(.collapsed) {
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #000;
}

.tab .accordion-button:not(.collapsed)::after {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.form-bottomline {
  border-radius: 0;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #dee2e6;
  color: var(--dark-blue);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-bottomline:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
  border-bottom: 1px solid var(--dark-blue);
}

.form-bottomline>div {
  border: none !important;
}

.form-bottomline.react-select {
  border-radius: 0 !important;
}

.form-bottomline.react-select:hover {
  border-bottom: 1px solid var(--dark-blue);
}

.form-bottomline.react-select [class*="-control"] {
  background-color: transparent;
}

.form-bottomline.react-select [class*="-control"]:focus-within {
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}

/*Stop || Pricing Page*/
.go-to-top {
  position: fixed;
  width: 44px;
  height: 44px;
  z-index: 1000;
  bottom: 100px;
  right: -120px;
  border-radius: 0px 0px 0 0;
  background: #000;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  font-size: 20px;
  line-height: 42px;
  color: #fff;
  -webkit-transition: 500ms;
  transition: 500ms;
  -webkit-box-shadow: 0 0px 10px #fff;
  box-shadow: 0 0px 10px #fff;
}

.go-to-top.active {
  right: 20px;
}

.go-to-top::before {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 18px;
  left: 16px;
  -webkit-transition: 500ms;
  transition: 500ms;
  border-radius: 2px;
}

.go-to-top:hover {
  background: #eda514;
}

/*Start || Footer*/
footer {
  color: #fff;
  background-color: #000;
  font-size: 15px;
  padding-top: 20px;
}

footer .form-control {
  border: none;
}

footer h5 {
  font-size: 22px;
  font-weight: bold;
}

footer .footer-links {
  list-style-type: none;
  padding: 0;
  margin: 0 0 50px 0;
}

footer .footer-links li {
  margin-bottom: 6px;
}

footer .footer-links li a {
  font-size: 15px;
  text-decoration: none;
  color: #fff;
}

footer .footer-links li a:hover {
  color: var(--bs-warning);
}

footer address {
  font-size: 15px;
  margin-bottom: 50px;
}

footer address a {
  color: #eda514;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 5px;
  text-decoration: none;
}

footer .social-links a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 6px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.75);
  margin: 2px;
}

footer .social-links a:hover {
  border: 1px solid rgba(255, 255, 255, 0);
  color: white;
}

footer .social-links a.icon-facebook:hover {
  background-color: #4267B2;
}

footer .social-links a.icon-twitter:hover {
  background-color: #00acee;
}

footer .social-links a.icon-linkedin:hover {
  background-color: #0077b5;
}

footer .social-links a.icon-instagram:hover {
  background-color: #833AB4;
}

footer .social-links a.icon-pinterest:hover {
  background-color: #E60023;
}

footer .social-links a.icon-google:hover {
  background-color: #DB4437;
}

footer .copyrights {
  background-color: #252525;
  padding: 10px 0;
}

footer .copyrights p {
  margin: 0;
}

/*Stop || Footer*/
.owl-theme .owl-stage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 10px;
}

.owl-theme .owl-dots,
.owl-theme .owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-dots .owl-dot span {
  width: 20px;
  height: 5px;
  margin: 5px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease;
  border-radius: 5px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-theme .owl-dots .owl-dot:hover span {
  background: var(--dark-blue);
}

@media screen and (max-width: 992px) {
  .navbar .navbar-collapse {
    overflow: hidden;
    overflow-y: auto;
    max-height: 90vh;
  }

  .navbar .navbar-collapse .navbar-nav {
    padding-top: 10px;
    padding-bottom: 30px;
  }

  .navbar .dropdown-item {
    white-space: normal;
  }
}

/*==================================================================================================*/
/* ========================== */
/* End | Style of Front Pages*/
/* ========================== */
/*==================================================================================================*/
/* ==================================== */
/* Start | Inside Admin Dashabord Style */
/* ==================================== */
.admin-aside {
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  width: 260px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 0;
}

.admin-aside .admin-logobox {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  margin-bottom: 15px;
}

.admin-aside .admin-logobox .admin-brandbox {
  padding: 16px 15px;
  text-decoration: none;
  text-align: center;
}

.admin-aside .admin-logobox .admin-brandbox img {
  width: 200px;
  margin: 0 auto;
  display: block;
  -webkit-filter: contrast(1000%);
  filter: contrast(1000%);
}

.admin-aside .admin-logobox .admin-dropdown .dropdown-toggle {
  width: calc(100% - 30px);
  margin: 0 auto;
  border-radius: 4px;
  padding: 6px 8px;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #eee;
  color: #444;
}

.admin-aside .admin-logobox .admin-dropdown .dropdown-toggle.show {
  color: #fff;
  background: #000;
}

.admin-aside .admin-logobox .admin-dropdown .dropdown-toggle.show::after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.admin-aside .admin-logobox .admin-dropdown .dropdown-toggle img {
  margin-right: 14px;
  border-radius: 4px;
}

.admin-aside .admin-logobox .admin-dropdown .dropdown-toggle span {
  width: calc(100% - 36px);
  position: relative;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  font-weight: 500;
}

.admin-aside .admin-logobox .aside-toggle {
  position: absolute;
  z-index: 10;
  width: 40px;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 28px;
  padding: 0;
  top: 0;
  border: none;
  right: -40px;
  background: #fff;
  outline: none;
  cursor: pointer;
  display: none;
}

.admin-aside .admin-navbox {
  border-top: 1px dashed rgba(var(--bs-dark-rgb), 0.2);
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 15px 10px 80px 0px;
  overflow: hidden;
  overflow-y: auto;
}

.admin-aside .admin-navbox .accordion-item {
  border-radius: 0;
  border: none;
  background: transparent;
}

.admin-aside .admin-navbox .accordion-item h6 {
  text-transform: uppercase;
  color: var(--light-blue);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 35px 10px 10px 15px;
  margin: 0;
  position: relative;
}

.admin-aside .admin-navbox .accordion-item .main-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  padding: 10px 15px;
  margin-bottom: 2px;
  border-radius: 0px 4px 4px 0;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  position: relative;
  background: rgba(0, 0, 0, 0.02);
  -webkit-transition: 350ms;
  transition: 350ms;
}

.admin-aside .admin-navbox .accordion-item .main-link::before {
  content: '';
  position: absolute;
  width: 7px;
  height: 7px;
  border-top: 2px solid currentColor;
  border-right: 2px solid currentColor;
  top: calc(50% - 3px);
  right: 12px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  opacity: 0;
  -webkit-transition: 300ms;
  transition: 300ms;
}

.admin-aside .admin-navbox .accordion-item .main-link[aria-expanded]::before {
  opacity: 0.85;
}

.admin-aside .admin-navbox .accordion-item .main-link[aria-expanded="true"]::before {
  top: calc(50% - 4px);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.admin-aside .admin-navbox .accordion-item .main-link:hover,
.admin-aside .admin-navbox .accordion-item .main-link[data-currentpage="true"] {
  background: rgba(0, 0, 0, 0.6);
  color: #eee;
}

.admin-aside .admin-navbox .accordion-item .main-link.active,
.admin-aside .admin-navbox .accordion-item .main-link[aria-expanded="true"] {
  background: black;
  color: #eee;
}

.admin-aside .admin-navbox .accordion-item .main-link [class*="icon"] {
  width: 20px;
  min-width: 20px;
  height: 20px;
  font-size: 20px;
  line-height: 1;
  margin-right: 12px;
  opacity: 0.75;
}

.admin-aside .admin-navbox .accordion-collapse {
  padding-left: 0px;
  font-size: 15px;
}

.admin-aside .admin-navbox .accordion-collapse .sub-link {
  display: block;
  text-decoration: none;
  padding: 8px 10px 8px 48px;
  margin-bottom: 2px;
  border-radius: 0 0.4rem 0.4rem 0;
  color: #444;
  font-weight: 400;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-transition: 350ms;
  transition: 350ms;
}

.admin-aside .admin-navbox .accordion-collapse .sub-link:last-child {
  margin-bottom: 20px;
}

.admin-aside .admin-navbox .accordion-collapse .sub-link::before {
  content: '';
  display: block;
  top: calc(50% - 4px);
  left: 20px;
  border-radius: 40px;
  position: absolute;
  width: 10px;
  height: 10px;
  border: 2px solid rgba(0, 0, 0, 0.8);
}

.admin-aside .admin-navbox .accordion-collapse .sub-link:hover {
  background: rgba(0, 0, 0, 0.15);
}

.admin-aside .admin-navbox .accordion-collapse .sub-link.active,
.admin-aside .admin-navbox .accordion-collapse .sub-link:active {
  color: #fff;
  background: rgba(0, 0, 0, 0.75);
}

.admin-aside .admin-navbox .accordion-collapse .sub-link.active::before,
.admin-aside .admin-navbox .accordion-collapse .sub-link:active::before {
  border: 2px solid rgba(255, 255, 255, 0.75);
}

.admin-aside .admin-copyrightsbox {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding: 5px 10px 5px 10px;
  font-size: 11px;
  font-weight: 500;
  color: #777;
  letter-spacing: 0.1px;
  text-align: center;
}

.admin-wrapper {
  padding-top: 15px;
  padding-bottom: 30px;
  padding-left: 260px;
  padding-right: 2px;
  background: transparent;
  min-height: 100vh;
}

.page-heading {
  position: relative;
  line-height: 1;
  font-size: 22px;
  font-weight: 800;
}

.welcome-hero {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 160px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #000;
  border-radius: 6px;
}

.welcome-hero img {
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  left: 0;
  top: 0;
  z-index: 0;
  opacity: 0.40;
  overflow: hidden;
}

.welcome-hero h2 {
  color: #fff;
  position: absolute;
  z-index: 1;
  margin: 0;
}

.tabs-common .btn {
  min-width: 100px;
  font-weight: 600;
  background: #fff;
  color: var(--bs-dark);
  border-color: var(--bs-dark);
  position: relative;
  margin: 0 12px 5px 0;
}

.tabs-common .btn.active {
  color: #fff;
  background: var(--bs-dark);
}

.upload-files {
  position: relative;
  border: 2px dashed #ced4da;
  min-height: 74px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.25rem;
  background-color: #fff;
  -webkit-box-shadow: inset 0 0 10px #fff;
  box-shadow: inset 0 0 10px #fff;
  text-align: center;
  color: #999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

.upload-files input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.upload-files:hover {
  border-color: rgba(var(--bs-dark-rgb), 0.5);
  color: var(--bs-dark);
}

.dashboard-link .admin-notification {
  height: 40px;
  padding: 0px 20px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-decoration: none;
  font-size: 16px;
  color: var(--dark-blue);
  background-color: #fff;
  border-radius: 6px;
  position: relative;
  vertical-align: middle;
  -webkit-transition: 350ms;
  transition: 350ms;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.075);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.075);
  cursor: pointer;
  outline: none;
  border: none;
}

.dashboard-link .admin-notification.active,
.dashboard-link .admin-notification:hover {
  color: #fff;
  background-color: var(--dark-blue);
}

.valid-input {
  border: 1px solid #198754 !important;
  border-radius: 5px !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated {
  .react-select {
    &.is-invalid {
      div {
        border-color: #dc3545 !important;
        border-radius: 5px !important;

        &:hover {
          border-color: #dc3545 !important;
        }
      }
    }

    &.is-valid {
      div {
        border-color: #198754 !important;
        border-radius: 5px !important;

        &:hover {
          border-color: #198754 !important;
        }
      }
    }

    div {
      &:hover {
        border-color: rgba(var(--dark-blue-rgb), 0.5) !important;
      }
    }
  }
}

.was-validated div:has(.invalid-custom-feedback) {
  .invalid-feedback {
    display: none;
  }
}

.table-responsive {
  position: relative;
}

.table-responsive::before {
  content: '';
  position: absolute;
  border-radius: 20px;
  top: 55px;
  bottom: 0;
  left: 0;
  background: #fff;
  width: 0;
  height: 3px;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
}

.table-responsive[data-tablespinner="true"]::before {
  opacity: 1;
  visibility: visible;
  -webkit-animation: tableloadingBar 4s linear infinite;
  animation: tableloadingBar 4s linear infinite;
}

.table-responsive[data-tablespinner="true"] table {
  pointer-events: none;
  opacity: 0.5;
}

@keyframes tableloadingBar {
  0% {
    width: 0%;
    background-color: var(--light-blue);
  }

  25% {
    background-color: var(--blue);
  }

  49% {
    width: 100%;
    background-color: var(--light-blue);
  }

  50% {
    background-color: var(--blue);
  }

  100% {
    width: 0%;
    background-color: var(--light-blue);
  }
}

.table-common {
  padding: 1px;
  width: 100%;
  background-color: #fff;
  border-collapse: separate;
  border-spacing: 0px 6px;
  -webkit-transition: 300ms;
  transition: 300ms;
}

.table-common thead tr th {
  font-size: 13.5px;
  letter-spacing: 0.25px;
  border: none;
  padding: 9px 14px;
  font-weight: 700;
  color: #fff;
  background-color: rgba(var(--bs-dark-rgb), 0.9);
  margin-bottom: 4px;
  text-transform: uppercase;
  position: relative;
}

.table-common thead tr th::before,
.table-common thead tr th::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
  right: 4px;
  margin-top: -7px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 7px solid currentColor;
  opacity: 0;
}

.table-common thead tr th::after {
  margin-top: 2px;
  border-bottom: 4px solid transparent;
  border-top: 6px solid currentColor;
}

.table-common thead tr th[data-orderby] {
  padding-right: 20px;
  cursor: pointer;
}

.table-common thead tr th[data-orderby]::before,
.table-common thead tr th[data-orderby]::after {
  opacity: 0.25;
}

.table-common thead tr th.active[data-orderby="asc"]::before {
  opacity: 0.75;
}

.table-common thead tr th.active[data-orderby="desc"]::after {
  opacity: 0.75;
}

.table-common thead tr th:first-child {
  border-radius: 4px 0 0 4px;
}

.table-common thead tr th:last-child {
  border-radius: 0 4px 4px 0;
}

.table-common tbody {
  padding: 0 2px;
}

.table-common tbody tr th,
.table-common tbody tr td {
  transition: 300ms;
  -webkit-transition: 300ms;
  border-bottom: 1px solid #ccc;
  box-shadow: inset 0 0 0 9999px transparent;
}

.table-common tbody tr th {
  font-size: 14.5px !important;
  letter-spacing: 0.25px;
  border: none;
  padding: 9px 14px;
  font-weight: 700;
  color: #fff;
  background-color: rgba(33, 37, 41, 0.9);
  margin-bottom: 4px;
  text-transform: uppercase;
}

.table-common tbody tr td {
  font-size: 14.5px !important;
  color: #6c757d !important;
  padding: 0.5rem 0.5rem;
}

.table-common tbody tr th:first-child,
.table-common tbody tr td:first-child {
  border-radius: 4px 0 0 4px;
}

.table-common tbody tr th:last-child,
.table-common tbody tr td:last-child {
  border-radius: 0 4px 4px 0;
}

.table-common tbody tr:hover td {
  color: #000000 !important;
}

.table-common tbody tr:hover {
  box-shadow: 0 0 1px 1px #eee !important;
  background-color: #eee !important;
  color: #000000 !important;
}

.table-layout-fixed {
  table-layout: fixed;
}

.form-search {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%236c757d' viewBox='0 0 512 512'%3E%3Cpath d='M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: 12px 11px;
  padding-left: 35px;
  border-radius: 6px;
}

.btn-pageof {
  border: 1px solid #ced4da;
}

.pagination {
  margin-top: 2px;
}

.pagination .page-item {
  height: 30px;
  min-width: 30px;
  text-decoration: none;
  display: inline-flex;
  border: 1px solid #dee2e6;
  margin-left: 2px;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  font-size: 14px;
  color: #212529;
}

.pagination .page-item.active .page-link {
  color: #fff;
  background-color: var(--bs-dark);
  border-color: var(--bs-dark);
}

.pagination .page-item.active {
  pointer-events: none;
}

.pagination .page-item.disabled {
  opacity: 0.6 !important;
  cursor: not-allowed !important;
}

.pagination .page-item.disabled .page-link {
  pointer-events: none !important;
  background-color: transparent;
}

.pagination .page-item.disabled .page-link:hover {
  opacity: 0.6 !important;
  background-color: #0d6efd !important;
  border-color: #0d6efd !important;
}

.pagination .page-item .page-link {
  color: #212529;
  min-width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 0.25rem;
  padding: 0.23rem 0.25rem 0.28rem 0.25rem;
  font-size: 14px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pagination .page-item .page-link:hover,
.pagination .page-item.active .page-link:hover,
.pagination .page-item.disabled .page-link:hover {
  background-color: #0d6efd !important;
  border-color: #0d6efd !important;
  color: #fff !important;
}

.pagination .page-item:first-child .page-link {
  padding: 0.22rem 0.85rem 0.28rem 0.85rem;
  border-radius: 4px 0 0 4px;
}

.pagination .page-item:last-child .page-link {
  padding: 0.22rem 0.85rem 0.28rem 0.85rem;
  border-radius: 0 4px 4px 0;
}

.tooltip.show {
  opacity: 1;
  -webkit-animation: tooltipanim 0.300s linear forwards;
  animation: tooltipanim 0.300s linear forwards;
}

.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: var(--bs-dark);
}

.tooltip.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: var(--bs-dark);
}

.tooltip.bs-tooltip-bottom {
  margin-top: 20px;
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: var(--bs-dark);
}

.tooltip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: var(--bs-dark);
}

.tooltip .tooltip-inner {
  background-color: var(--bs-dark);
  -webkit-box-shadow: 0 7px 15px rgba(0, 0, 0, 0.2);
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 10px 14px;
  font-size: 15px;
  opacity: 1;
  text-align: left;
}

@-webkit-keyframes tooltipanim {
  from {
    margin-top: 10px;
    opacity: 0;
  }

  to {
    margin-top: 0px;
    opacity: 1;
  }
}

@keyframes tooltipanim {
  from {
    margin-top: 10px;
    opacity: 0;
  }

  to {
    margin-top: 0px;
    opacity: 1;
  }
}

/*Start | Star Rating*/
.star-rating {
  display: inline-block;
  color: #ccc;
  font-family: Arial;
  vertical-align: top;
  position: relative;
}

.star-rating::before {
  content: '\2605\2605\2605\2605\2605';
  font-family: inherit;
  font-size: 25px;
  line-height: 25px;
  letter-spacing: 5px;
  text-shadow: 0 1px 0px #000;
}

.star-rating span {
  display: block;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  white-space: nowrap;
  -webkit-transition: 350ms linear;
  transition: 350ms linear;
  pointer-events: none;
}

.star-rating span::before {
  content: '\2605\2605\2605\2605\2605';
  position: relative;
  font-family: inherit;
  color: #ff9f43;
  left: 0;
  top: 0;
  width: 100%;
  display: block;
  font-size: 25px;
  line-height: 25px;
  letter-spacing: 5px;
}

/*End | Star Rating*/
/* Start | Toast Style */

.toast-end {
  .toast {
    letter-spacing: 0.4px;
    overflow: hidden;
    font-size: 15.5px;

    &.show {
      opacity: 1;
      animation: toastanim 0.350s linear forwards;
    }

    &.hide {
      display: block;
      opacity: 1;
      animation: toastanimreverse 0.350s linear forwards;
    }

    .bg-warning,
    .bg-light {
      .toast-body {
        color: #000 !important;
      }

      .btn-close {
        background-color: rgba(0, 0, 0, 0.05);
        filter: invert(0);

        &:hover {
          filter: invert(1);
          background-color: rgba(4, 255, 255, 0.6);
        }
      }
    }

    .btn-close {
      background-color: rgba(255, 255, 255, 0.05);
      filter: invert(1);
      opacity: 1;
      transition: 300ms;
      width: 22px;
      min-width: 22px;
      height: 22px;
      max-height: 22px;

      &:hover {
        background-color: rgba(4, 255, 255, 0.6);
      }
    }
  }
}

@-webkit-keyframes toastanim {
  from {
    -webkit-transform: translateX(300px);
    transform: translateX(300px);
  }

  to {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes toastanim {
  from {
    -webkit-transform: translateX(300px);
    transform: translateX(300px);
  }

  to {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-webkit-keyframes toastanimreverse {
  from {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  to {
    -webkit-transform: translateX(600px);
    transform: translateX(600px);
  }
}

@keyframes toastanimreverse {
  from {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  to {
    -webkit-transform: translateX(600px);
    transform: translateX(600px);
  }
}

/* End | Toast Style */
.react-select [class*="-control"]:hover {
  border-color: rgba(var(--light-blue-rgb), 0.75);
}

.react-select [class*="-control"]:focus-within {
  border: 1px solid rgba(var(--light-blue-rgb), 0.75) !important;
  outline: 0 !important;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(var(--light-blue-rgb), 0.35) !important;
  box-shadow: 0 0 0 0.25rem rgba(var(--light-blue-rgb), 0.35) !important;
}

.overlay-onmobile {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
}

.text-intwolines {
  display: -webkit-box;
  max-height: 48px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.625;
}

.nav-common .nav-link.active {
  background: #ddd;
  color: #000;
  border-left: 3px solid #001e3c;
}

/*Start | Tablet + Mobile Style*/
@media (max-width: 1199.9px) {
  .admin-aside {
    left: -260px;
    -webkit-transition: 350ms;
    transition: 350ms;
    z-index: 101;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .admin-aside .admin-logobox .aside-toggle {
    display: block;
  }

  .menu-active .admin-aside {
    left: 0px;
  }

  .menu-active .overlay-onmobile {
    visibility: visible;
    opacity: 1;
    -webkit-transition: 350ms;
    transition: 350ms;
  }

  .admin-wrapper {
    padding-top: 15px;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 574.9px) {
  .admin-wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }
}

/*End | Tablet + Mobile Style*/
#root .admin-wrapper .container-fluid {
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-animation: pageanimation 450ms linear forwards;
  animation: pageanimation 450ms linear forwards;
}

@-webkit-keyframes pageanimation {
  from {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
    opacity: 0.1;
  }

  to {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes pageanimation {
  from {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
    opacity: 0.1;
  }

  to {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}

@media (min-width: 992px) {
  .w-lg-75 {
    width: 75%;
  }

  .w-lg-80 {
    width: 80%;
  }

  .w-lg-85 {
    width: 85%;
  }
}

.iti {
  display: block !important;
}

.card-loader {
  position: relative;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
}

.card-header {
  background-color: rgba(0, 0, 0, 0.09);
}

.card-loader .card-header,
.card-loader .card-body {
  opacity: 0;
}

.card-loader::after {
  content: '';
  position: absolute;
  width: 40px;
  height: 40px;
  border: 3px solid currentColor;
  border-left: 3px solid transparent;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  border-radius: 50px;
  animation: cardloader 1s linear infinite
}

@keyframes cardloader {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.first-part,
.second-part,
.third-part {
  display: inline-block;
  height: 15px;
}

.first-part {
  background: #da495b;
  width: 33.333333333%;
  float: left;
}

.second-part {
  background: #4662a0;
  width: 33.333333333%;
  float: left;
}

.third-part {
  background: #aadb87;
  width: 33.333333333%;
  float: left;
}

.line-engage-part {
  max-width: 340px;
  margin: 0px auto;
}

.clearfix {
  clear: both;
}

.engage-values {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  max-width: 340px;
  margin: auto;
  margin-bottom: 10px;
  padding: 0 15px;
}

.rating-dash:before {
  position: absolute;
  color: #018dc8;
  font-family: FontAwesome;
  left: 0px;
  top: 0px;
  z-index: 1;
}

.rating-1:before {
  content: '\f005'
}

.rating-1-5:before {
  content: '\f005 \f123'
}

.rating-2:before {
  content: '\f005 \f005'
}

.rating-2-5:before {
  content: '\f005 \f005 \f123'
}

.rating-3:before {
  content: '\f005 \f005 \f005'
}

.rating-3-5:before {
  content: '\f005 \f005 \f005 \f123'
}

.rating-4:before {
  content: '\f005 \f005 \f005 \f005'
}

.rating-4-5:before {
  content: '\f005 \f005 \f005 \f005 \f123'
}

.rating-5:before {
  content: '\f005 \f005 \f005 \f005 \f005'
}

.segmentsd-tail {
  text-align: center;
  font-size: 20px;
  line-height: 20px;
  margin-top: 10px;
}

.segmentsd-tail p {
  margin-bottom: 0px;
  border-bottom: 1px solid #f1f1f1;
  padding-top: 15px;
  padding-bottom: 15px;
}

.segmentsd-tail p:last-child {
  border-bottom: none;
}

.segmentsd-tail p span {
  color: #555;
  font-size: 14px;
}

.segmentsd-tail .seg-pos {
  color: #4662a0;
}

.segmentsd-tail .seg-neu {
  color: #aadb87;
}

.segmentsd-tail .seg-neg {
  color: #da495b;
}

/*Start  Location Listings Status */

.three-listingxt {
  border: 1px solid #eee;
  min-height: 105px;
  padding: 10px 0px;
}

.view-text {
  font-size: 17px;
  color: #555;
}

.view-text i {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  line-height: 32px;
  display: inline-block;
  text-align: center;
  background: #00BCD4;
  color: #fff;
  font-size: 16px;
  font-style: normal;
}

h3.engage-data {
  font-size: 25px;
  color: #00BCD4;
  font-weight: normal;
}

.slogan-text {
  font-size: 13px;
  color: #999;
  white-space: nowrap;
}

.listings-locstasnacimg {
  margin: 0px;
  padding: 0px;
  text-align: center;
}

.listings-locstasnacimg li {
  display: inline-block;
  margin: 0px;
  padding: 0px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 15px;

}

.listings-locstasnacimg li span {
  width: 70px;
  height: 70px;
  text-align: center;
  display: inline-block;
  white-space: nowrap;
  box-sizing: border-box;
  color: #888;
  font-size: 12px;
}

.listings-locstasnacimg li span:hover {
  background: #eee;
  text-decoration: none;
}

.listings-locstasnacimg li span img {
  display: inline-block;
  width: 45px;
  text-align: center;
  margin-bottom: 5px;
}

.total-locstatus-content {
  position: relative
}

.total-locstatus-content .grid_loader {
  margin-right: -17px;
  margin-top: -17px;
}

.total-locstatus-content {
  text-align: center;
}

.total-locstatus-content h2 {
  font-size: 22px;
  color: #777;
}

.total-locstatus-content p {
  font-size: 14px;
  color: #999;
}

.total-locationstatusbor h2 {
  font-size: 27px;
}

@media (max-width: 1450px) {
  .listings-locstasnacimg li span {
    width: 62px;
    height: 62px;
  }

  .listings-locstasnacimg li {
    margin-left: 4px;
    margin-right: 4px;
  }
}

.bodter-toponlythis {
  border-right: 2px solid #eee;
  background: rgba(241, 241, 241, 0.3);
}

@media (max-width: 1280px) {
  .listings-locstasnacimg li {
    margin-left: 2px;
    margin-right: 2px;
  }
}

.alert-danger {
  color: #868686;
  background-color: transparent;
  border-color: transparent;
  font-style: italic;
  font-size: 14px;
  text-align: center;
}

.commondasboard-tilsatr {
  border: 1px solid #e7e7e7;
  margin-bottom: 15px;
  min-height: 310px;
  padding: 0px 5px;
}

.commondasboard-tilsatr .card-header {
  border: none;
  background: #fff;
  font-size: 18px;
  color: #444;
  padding: 5px 5px 5px 5px;
  font-weight: 7000;
}

.commondasboard-tilsatr .card-body {
  padding: 0px 15px 0px 15px;
}

.listings-locstasnacimg.active .social {
  opacity: 0.5;
  filter: grayscale(100%);
}

.listings-locstasnacimg.active .social[data-status="true"] {
  opacity: 1;
  filter: grayscale(0);
}

.passhints {
  list-style: none;
  font-size: 13px;
  list-style: none;
  padding: 5px 0px 10px 10px;
  background: #fff;
  border-radius: 4px;
  position: absolute;
  width: calc(100% - 30px);
  box-sizing: border-box;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.30);
  overflow: hidden;
  top: 42px;
  height: 0;
  transform-origin: top;
  transform: scaleY(0);
  z-index: 10;
}

#new_password:focus~.passhints,
.form-passwordhints:focus~.passhints {
  height: auto;
  transform: scaleY(1);
}

.passhints li {
  line-height: 1.9em;
  color: #555;
}

.passhints li:before {
  content: '';
  height: 11px;
  width: 11px;
  background-color: #646464;
  display: inline-block;
  margin-right: 7px;
  position: relative;
  top: 0.5px;
  border-radius: 6px;
}

.passhints li.pwpass {
  text-decoration: line-through;
  color: #00C176;
}

.passhints li.pwfail:before {
  background-color: #FF003C;
}

.passhints li.pwpass:before {
  background-color: #00C176;
}

.siqico-chat {
  display: none;
}


.glow {
  animation: glow 1s linear infinite;
}

@keyframes glow {

  0%,
  100% {
    transform: scale(0.9)
  }

  50% {
    transform: scale(1.2)
  }
}

.zsiq_theme1.zsiq_floatmain,
.logedin .zsiq_theme1.zsiq_floatmain {
  min-height: 60px;
  opacity: 0 !important;
  display: none !important;
}

.siqanim,
.logedin .siqanim {
  transform: scale(0) !important;
}

/* PRELOADER CSS */
.page-loader {
  width: 100%;
  height: 100vh;
  position: absolute;
  background: #272727;
  z-index: 1000;
  opacity: 0.6;

  .txt {
    color: #666;
    text-align: center;
    top: 40%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
  }
}

/* SPINNER ANIMATION */
.spinner {
  position: relative;
  top: 35%;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0)
  }

  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}

.aside_menu_selected {
  background: rgba(0, 0, 0, 0.6);
  color: #eee;
}

.bg-lpurple {
  background: #8080ff;
}

.bg-dpurple {
  background: #3E0562;
}

.bg-yellow {
  background: #ffc107;
}

.bg-pink {
  background: #e91e63;
}

.bg-lgreen {
  background: #e0e07e;
}

.more-colors {
  line-height: 30px;
  font-size: 10px;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.75);
}

li.more-colors label {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

li.more-colors input {
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
  outline: none;
  box-shadow: none;
}

/*** business card 26 december 2022 **/
/** frist business card ***/
.card-box-container {
  background: #f1f1f12e;
  height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .flip {
    transform: rotateX(180deg);
  }

  .card-box {
    flex-direction: column !important;
    display: flex;
    align-items: center;
    height: 100%;
  }

  .card1 {
    position: relative;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 100%;
    max-width: 465px;
    box-sizing: border-box;
    margin-top: 50px;

    .front {
      z-index: 2;
      background: #fff;
    }

    .front section {
      padding: 10px;
    }

    p {
      word-break: break-all;
    }
  }

  .card1 .front,
  .card1 .back {
    height: inherit !important;
    width: inherit;
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    width: 100%;

  }

  .card1 .front .flipButton,
  .card1 .back .flipButton {
    position: absolute;
    bottom: -50px;
    left: 50%;
    margin-left: -30px;
  }

  .btn-theming {
    border: 1px solid #001e3c;
    background: #001e3c;
    color: #fff;
    transition: all 0.5s;
  }

  .border-dotted {
    border: 1px dotted #ddd !important;
  }

  .card-logo {
    max-width: 145px !important;
    max-height: 90px !important;
    object-fit: contain;
    margin: 0px auto;
  }
 

  .set-logo img {
    margin: 0px auto;
    width: auto;
    max-width: 200px;
    height: auto;
   
  }

  .card-fs {
    font-size: 15px !important;
    word-break: break-all !important;
  }

  .w-40per {
    width: 40% !important;
    padding-left: 5px;
    padding-right: 5px;
  }

  .w-45per {
    width: 45% !important;
    padding-left: 5px;
    padding-right: 5px;
  }

  .w-50per {
    width: 50% !important;
    padding-left: 5px;
    padding-right: 5px;
  }

  .w-55per {
    width: 55% !important;
    padding-left: 5px;
    padding-right: 5px;
  }

  .w-60per {
    width: 60% !important;
    padding-left: 5px;
    padding-right: 5px;
  }

  .card1 {
    .back {
      -webkit-transform: rotateY(180deg);
      transform: rotateY(180deg);
      color: #fff;
      z-index: 11;
      background: #fff;
    }

    .back section {
      padding: 10px;
    }
  }

  .btn-theming {
    border: 1px solid #001e3c;
    background: #001e3c;
    color: #fff;
    transition: all 0.5s;
  }

  .border-dotted {
    border: 1px dotted #ddd !important;
  }

  .logo-back {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .logo-back .back-image {
      width: 247px;
      height: 135px;
  
  }

  .logo-back {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    color: #000;
  }
 
  .card1.flip {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }

  .btn-theming:hover {
    background: #eda514;
    border-color: #eda514;
    box-shadow: 0 14px 26px -12px rgb(25 104 252 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(25 104 252 / 20%) !important;
    opacity: 0.95;
  }

}

/** frist  card  business card end **/
/** second  card  business card end **/
.logo-box-area {
  width: 190px;
  display: flex;
  align-items: center;
}

/** third  card  business card end **/
.third-business-card {
  display: flex;
  flex-direction: row-reverse;

  .w-35per {
    width: 40% !important;
    padding-left: 5px;
    padding-right: 5px;
  }

  .w-65per {
    width: 60% !important;
    padding-left: 5px;
    padding-right: 5px;
  }
}

/** five  card  business card end **/

.card-width-50 {
  width: 50%;
}
.card-design-six {
  position: relative;
  left: -14px;
  top: -12px;
}
.card-six {
  .card-background {
    padding: 11px 14px 29px 14px;
  }

  .cdr-img img {
    width: 100%;
    max-width: 172px;
  }

  .para2 span,
  .para {
    font-size: 12px;
  }

  .data-move {
    width: 100% !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cdr-img {
    position: absolute;
    left: 1px;

  }
}

.img_back_six {
  .back-logo {
    position: absolute;
    top: 49%;
    left: 47%;
    height: 150px;
    max-width: 400px;
    img {
      height: 108px;
      max-width: 201px;
      object-fit: fill;
    }
  }
}
.abc{
  width: 252px;
    height: 120px;
    margin-left: 10px;
    margin-top: 104px;
}


/** business card end **/

.iti__flag-container {
  height: 36px;
}

.pointer-event-active {
  pointer-events: all !important;
  cursor: pointer;
}

.graph-box {
  position: relative;
  padding-left: 80px;
  height: inherit;
}

.percentage-bar {
  width: 40px;
  height: 100%;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 1;
}

.percentage-bar div {
  width: 100%;
  transition: 50ms;
  cursor: default;
  position: relative;
}

.percentage-bar div:hover {
  outline: 1px solid #333;
  outline-offset: 0px;
  z-index: 2;
}

.percentage-bar div:before {
  content: attr(data-percentage);
  font-weight: normal;
  background: #333;
  color: #eee;
  width: 60px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  position: absolute;
  margin: 0px;
  top: -10px;
  left: -10px;
  text-align: center;
  pointer-events: none;
  transition: 400ms;
  opacity: 0;
  outline: 1px solid #000;
  outline-offset: -3px;
  letter-spacing: 0.8px;
}

.percentage-bar div:hover:before {
  opacity: 1;
  top: -35px;
}

.percentage-bar div:after {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  top: 5px;
  left: 50%;
  margin-left: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #333;
  transition: 400ms;
  opacity: 0;
}

.percentage-bar div:hover:after {
  opacity: 1;
  top: -7px;
}

.bar-green {
  background: #00d1bc;
}

.bar-perpol {
  background: #8d26d6;
}

.bar-gray {
  background: #bababa;
}

.graph-listing {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
}

.graph-listing li {
  background: #eee;
  margin-bottom: 10px;
  position: relative;
  padding-left: 80px;
  border-left: 3px solid transparent;
  transition: 400ms;
}

.graph-listing li.line-green.active {
  box-shadow: 0px 0px 0px 2px #00d1bc
}

.graph-listing li.line-green {
  border-left: 3px solid #00d1bc;
}

.graph-listing li.line-perpol.active {
  box-shadow: 0px 0px 0px 2px #8d26d6
}

.graph-listing li.line-perpol {
  border-left: 3px solid #8d26d6;
}

.graph-listing li.line-gray.active {
  box-shadow: 0px 0px 0px 2px #bababa
}

.graph-listing li.line-gray {
  border-left: 3px solid #bababa;
}

.graph-listing li p:before {
  content: attr(data-percentage);
  font-weight: lighter;
  font-size: 28px;
  line-height: 44px;
  position: absolute;
  margin: 0px;
  left: 0px;
  width: 90px;
  height: 100%;
  text-align: center;
}

.graph-listing li.line-green p:before {
  color: #00d1bc
}

.graph-listing li.line-perpol p:before {
  color: #8d26d6
}

.graph-listing li.line-gray p:before {
  color: #777
}

.graph-listing li p {
  margin: 0px;
  padding: 10px;
  color: #777
}

.graph-listing li p strong {
  display: block;
  font-size: 14px;
  color: #555;
}

.graph-listing li p small {
  color: #fff;
  padding: 1px 5px;
  border-radius: 2px;
  margin-left: 5px;
  font-weight: bold;
}

.graph-listing li.line-green p small {
  background: #00d1bc;
}

.graph-listing li.line-perpol p small {
  background: #8d26d6;
}

.graph-listing li.line-gray p small {
  background: #777;
}

.bar-blue {
  background: #018dc8;
}

.graph-listing li.line-blue {
  border-left: 3px solid #018dc8;
}

#ui-datepicker-div {
  z-index: 1111 !important;
}

.intl_phone .iti__flag-container {
  height: 38px;
}

.intl_phone .iti__selected-flag {
  background-color: #e6e6e6 !important;
  border-radius: 0.375rem 0rem 0rem 0.375rem;
}
.css-1nmdiq5-menu {
  z-index: 11 !important ;
}
/*=====arclight-model-buttom========*/
@media (max-width: 380px) and  (min-width: 280px) {
  .closeBtn {
    font-size: 12px !important;
    padding: 5px !important;
}
.downloadBtn {
  padding: 5px !important;
  font-size: 12px !important;
}
}

.template-arclight{
  font-family: 'Montserrat', sans-serif !important;
}

.dd-manage-user-role {
  padding-left: 5px;
}

.dd-manage-user-role option {
  text-align: left;
}


// arif's css for chat box in support page start
.border-partition {
  border-right: 1px solid #ddd;
  padding-right: 15px;
}

.ticket-message-content {
  border: 1px solid #001e3b47;
  border-radius: 4px;
}
  
.direct-chat-messages {
  -webkit-transform: translate(0,0);
  transform: translate(0,0);
  height: 350px;
  overflow: auto;
  padding: 10px;
}

.direct-chat-infos {
  display: block;
  font-size: .775rem;
  margin-bottom: 2px;
}

.direct-chat-name {
  font-weight: 600;
}

.float-left {
  float: left!important;
}
.direct-chat-timestamp {
  color: #697582;
}

.float-right {
  float: right!important;
}

.direct-chat-img {
  border-radius: 50%;
  float: left;
  height: 40px;
  width: 40px;
}

.right .direct-chat-img {
  float: right !important;
}

.direct-chat-text {
  border-radius: 0.3rem;
  background-color: #d2d6de;
  border: 1px solid #d2d6de;
  color: #444;
  margin: 5px 0 0 50px;
  padding: 5px 10px;
  position: relative;
  word-wrap:break-word; 
  display:inline-block
}


.direct-chat-primary .right>.direct-chat-text {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
}

.right .direct-chat-text {
  margin-left: 0;
  margin-right: 50px;
}

.direct-chat-msg, .direct-chat-text {
  display: block;
}

.attachment-list-box {
  display: inline-block;
}

.attachment-list {
  border: 1px solid #ddd !important;
  padding: 13px 10px;
  text-decoration: none;
  color: #fff;
  background: #202529;
  border-radius: 10px;
  box-shadow: none !important;
}

.attachment-list:hover {
  color: #fff !important;
}

.attachment-list-box li {
  margin: 10px 0;
  padding: 10px 0 !important;
}

.web-template-ds01 {
  display: block;
  width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 15px;
  margin: 0 auto;
  height: 400px;
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  img {
    width: 100%;
    height: auto;
    position: absolute;
    z-index: 0;
    margin:0;
    padding:0;
    -webkit-transition: top 11s;
    -moz-transition: top 11s;
    -ms-transition: top 11s;
    -o-transition: top 11s;
    transition: bottom 11s;
    &:hover{
      bottom: 0 !important;
      -webkit-transition: all 11s;
      -moz-transition: all 11s;
      -ms-transition: all 11s;
      -o-transition: all 11s;
      transition: all 11s;
    }
  }
}


.dropdown-menu .nav-item.dropdown .dropdown-menu.show {
  opacity: 1;
  visibility: visible;
  display: block;
  left: 100%;
  top: 0;
  margin-top: 0 !important;
  border-top-left-radius: 0 !important;
}
.dropdown-menu .nav-item.dropdown .dropdown-item.dropdown-toggle::after {
  margin-left: auto;
  transform: rotate(-90deg);
}

.dropdown-menu .nav-item.dropdown .dropdown-item.dropdown-toggle {
  display: flex;
  align-items: center;
}
// arif's css for chat box in support page end

